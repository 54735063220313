import React from 'react';
import {SelectField, SelectInput} from 'react-admin';

export const eventTypes = [
    {id: 'ATTENDANCE', value: 'Điểm danh', name: 'eventType.ATTENDANCE'},
    {id: 'LIMIT_QUANTITY', value: 'Giới hạn số lượng', name: 'eventType.LIMIT_QUANTITY'},
    {id: 'FULL_DAY_MEAL', value: 'Ăn bán trú', name: 'eventType.FULL_DAY_MEAL'},
    {id: 'NAP', value: 'Ngủ trưa', name: 'eventType.NAP'},
    {id: 'KIOT_MEAL', value: 'Suất ăn quầy hàng', name: 'eventType.KIOT_MEAL'},
]

export const EventTypeField = (props) => (
    <SelectField source={props.source} choices={eventTypes} {...props} />
);

export const EventTypeInput = (props) => (
    <SelectInput source={props.source} choices={eventTypes} {...props} label="resources.paymentMethod.name"/>
);
