import React, {useCallback, useEffect, useState} from "react";
import axiosInstance from "../../api/axios";
import InfiniteScroll from "react-infinite-scroll-component";
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import debounce from "debounce";
import {NumberField, Title} from "react-admin";
import moment from "moment";
import {DateTimeField, showErrorNotify, showSuccessNotify} from "../../components";
import KiotOrderDetailDialog from "../../components/KiotOrderDetailDialog";
import IconSearch from '@material-ui/icons/Search';
import utils from "../../utils/utils";

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing.unit,
        width: 300
    },
    select: {
        maxHeight: 320
    },
    suggestDialog: {
        zIndex: '10 !important',
    }
});

const Filter = withStyles(styles)(({
                                       searchParams,
                                       setSearchParams,
                                       doFilter,
                                       kiots,
                                       classes,
                                       loading
                                   }) => {

    const changeFilter = (value) => {
        setSearchParams(params => ({...params, q: value}))
    }

    const updateFromDate = useCallback(debounce((value) => {
        setSearchParams(params => ({...params, fromDate: value}))
    }, 300), [])

    const updateToDate = useCallback(debounce((value) => {
        setSearchParams(params => ({...params, toDate: value}))
    }, 300), [])

    const onSelectChange = useCallback(debounce((e) => {
        setSearchParams(params => ({...params, [e.target.name]: e.target.value}))
    }, 300), [])

    return <div style={{padding: '0 16px 8px 0'}}>
        <TextField
            label="Tìm kiếm"
            onChange={(e) => changeFilter(e.target.value)}
            onKeyUp={(event) => {
                if (event.key === 'Enter')
                    doFilter()
            }}
            InputLabelProps={{shrink: true}}
            style={{marginRight: 10}}
        />

        <TextField
            label="Từ ngày"
            type="date"
            defaultValue={searchParams.fromDate}
            onChange={(e) => updateFromDate(e.target.value)}
            style={{marginRight: 10}}
            InputLabelProps={{
                shrink: true,
            }}
        />

        <TextField
            label="Đến ngày"
            type="date"
            defaultValue={searchParams.toDate}
            onChange={(e) => updateToDate(e.target.value)}
            style={{marginRight: 10}}
            InputLabelProps={{
                shrink: true,
            }}
        />
        <FormControl className={classes.formControl}>
            <InputLabel shrink>Quầy hàng</InputLabel>
            <Select
                value={searchParams.kiotConfigId}
                onChange={onSelectChange}
                displayEmpty
                inputProps={{
                    name: 'kiotConfigId',
                }}
            >
                {kiots.map(status => <MenuItem key={status.id} value={status.id}>{status.name}</MenuItem>)}
            </Select>
        </FormControl>
        <Button color="primary" disabled={loading} onClick={doFilter} variant="outlined">
            <span style={{marginRight: 6}}>Tìm kiếm</span>
            <IconSearch/>
        </Button>
    </div>
})

const KiotOrdersComponent = (props) => {
    const {status, screenTitle, tab} = props;
    const [items, setItems] = useState([])
    const [kiots, setKiots] = useState([])
    const [hasMore, setHasMore] = useState(true)
    const [showDetail, setShowDetail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [selectedOrder, setSelectedOrder] = useState({})

    const [searchParams, setSearchParams] = useState({
        q: '',
        kiotConfigId: '',
        pageIndex: 0,
        fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
    })

    useEffect(() => {
        setLoading(true)
        fetchData(true, searchParams)
    }, [])

    const doFilter = async () => {
        setHasMore(true)
        setLoading(true)
        setItems([])
        searchParams.pageIndex = 0;
        setSearchParams(params => ({...params, pageIndex: 0}))
        fetchData(true, searchParams)
    }

    const loadingMore = () => {
        fetchData(false, searchParams)
    }
    const fetchData = (reset, searchParams) => {
        const finalSearchParam = {...searchParams}
        finalSearchParam.toDate = moment(searchParams.toDate).add(1, 'days').format('YYYY-MM-DD')
        const params = new URLSearchParams(finalSearchParam)
        let urlPrefix = status === '3' ? '/api/kiotDeliveredOrders?' : tab === 1 ? '/api/kiotImportOrders?' : '/api/kiotOrders?'
        axiosInstance(`${urlPrefix}${params}`).then(({data}) => {
            setLoading(false)
            if (data) {
                setSearchParams(params => ({...params, kiotConfigId: data.kiotConfigId}))
                setKiots(data.kiots)
                if (data.data.length > 0) {
                    let newData
                    if (reset) {
                        newData = data.data
                    } else {
                        newData = items.concat(data.data)
                    }

                    setItems(newData)
                    setSearchParams(params => ({...params, pageIndex: data.pageIndex + 1}))
                    const pageSize = 100;
                    if (data.data.length < pageSize) {
                        setHasMore(false)
                    } else {
                        setHasMore(true)
                    }
                } else {
                    setHasMore(false)
                }
            } else {
                setHasMore(false)
            }
        }).catch((e) => {
            setLoading(false)
            setHasMore(false)
        })
    }

    const deliveredOrder = () => {
        setLoading(true)
        const data = {
            description: selectedOrder.description
        }
        const url = tab === 1 ? `/api/kiotImportOrders/${selectedOrder.id}/config/${searchParams.kiotConfigId}` : `/api/kiotOrders/${selectedOrder.id}/config/${searchParams.kiotConfigId}`;
        axiosInstance.put(url, data)
            .then(({data}) => {
                setShowDetail(false)
                setLoading(false)
                if (data == null || !data.success) {
                    showErrorNotify(tab === 1 ? 'Nhận hàng không thành công, vui lòng kiểm tra lại hoặc liên hệ quản trị viên!' : 'Giao hàng không thành công, vui lòng kiểm tra lại hoặc liên hệ quản trị viên!')
                } else {
                    showSuccessNotify(tab === 1 ? 'Nhận hàng thành công!' : 'Giao hàng thành công!')
                    const arr = [...items]
                    setItems(arr.filter(i => i.id !== selectedOrder.id))
                    setShowDetail(false)
                    setSelectedOrder(null)
                }
            })
            .catch(error => {
                setLoading(false)
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
            })
    }

    const viewOrderDetail = (order) => {
        setLoading(true)
        let urlPrefix = tab === 1 ? `/api/kiotImportOrders/${order.code}/${searchParams.kiotConfigId}` : `/api/kiotOrders/${order.code}/${searchParams.kiotConfigId}`
        axiosInstance.put(`${urlPrefix}`, {description: order.description}).then(({data}) => {
            setSelectedOrder(data)
            setShowDetail(true)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }

    const handleCloseDialogDetail = () => {
        setSelectedOrder({})
        setShowDetail(false)
    }

    const calculateTotalItems = () => {
        return items.length;
    }

    const calculateTotalCost = () => {
        let total = 0;
        for (let i = 0; i < items.length; i++) {
            total += items[i].totalPayment
        }
        return utils.formatNumber(total);
    }

    return <Card>
        <Title title={screenTitle}/>
        <CardContent>
            <div>
                <Filter searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        loading={loading}
                        kiots={kiots}
                        doFilter={doFilter}
                />
                <div style={{marginBottom: 8}}>Tổng số đơn hàng: {calculateTotalItems()}</div>
                <div style={{marginBottom: 8}}>Tổng tiền: {calculateTotalCost()}</div>
                <InfiniteScroll
                    dataLength={items.length}
                    next={loadingMore}
                    hasMore={hasMore}
                    height={window.innerHeight - 250}
                    loader={<CircularProgress style={{margin: 10}} color="secondary"/>}
                >
                    <Table>
                        <TableHead style={{position: 'sticky', top: 0, zIndex: 1}}>
                            <TableRow>
                                <TableCell padding='none'>Mã đơn hàng</TableCell>
                                <TableCell>Người mua</TableCell>
                                <TableCell>Ngày đặt</TableCell>
                                <TableCell>Tổng tiền</TableCell>
                                <TableCell>Trạng thái</TableCell>
                                <TableCell>Hành động</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((row, idx) => (
                                <TableRow key={idx}>
                                    <TableCell padding='none' width={110}>{row.code}</TableCell>
                                    <TableCell>{row.customerName}</TableCell>
                                    <TableCell width={160}>
                                        <DateTimeField record={{...row}} source='purchaseDate'
                                                       format='DD/MM/YYYY HH:mm:ss'/>
                                    </TableCell>
                                    <TableCell width={120}>
                                        <NumberField record={{...row}} source="totalPayment" locales="vi-VI"
                                                     options={{style: 'currency', currency: 'VND'}}/>
                                    </TableCell>
                                    <TableCell width={120}>{row.statusValue}</TableCell>
                                    <TableCell padding={'none'}>
                                        <Button disabled={loading} color="primary" onClick={() => viewOrderDetail(row)}
                                                variant="outlined">
                                            <span style={{marginRight: 6}}>Xem chi tiết</span>
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                </InfiniteScroll>
            </div>
            <KiotOrderDetailDialog showDialogDetail={showDetail} orderDetail={selectedOrder}
                                   deliveredOrder={deliveredOrder}
                                   tab={tab}
                                   loading={loading}
                                   handleCloseDialogDetail={handleCloseDialogDetail}/>
        </CardContent>
    </Card>

};

export default KiotOrdersComponent;
