export const menus = [
    {
        name: "school_menu",
        children: [
            {
                name: 'schools',
            },
            {
                name: 'schoolYears',
            },
            {
                name: 'grades',
            },
            {
                name: 'clazzes',
            },
            {
                name: 'studentEnrollments',
            },
            {
                name: 'promote',
            },
            {
                name: 'migrateStudent',
            },
            {
                name: 'dsMaQrHS',
            },
            {
                name: 'studentEnrollmentBatches',
            },
            {
                name: 'pupilEnrollmentBatches',
            },
            {
                name: 'pupilEnrollmentBatchV2s',
            },
            {
                name: 'upload-photo-permissions'
            },
            {
                name: 'pupilEnrollmentBatchV3s',
            },
            {
                name: 'teacherAssignmentBatches',
            },
            {
                name: 'customerInfoBatches',
            },
            {
                name: 'clazzBatches',
            },
        ]
    },
    {
        name: "school_reports_menu",
        children: [
            {
                name: 'reportSchoolAttendance',
            },
            {
                name: 'reportClazzAttendance',
            },
            {
                name: 'reportStudentAttendance',
            },
            {
                name: 'dateKeepingReport',
            },
            {
                name: 'busAttendanceReport',
            },
            {
                name: 'semiBoardingMissingReport',
            },
            {
                name: 'mealCheckingReport',
            },
            {
                name: 'weeklyReport',
            },
            {
                name: 'eventReport',
            },
            {
                name: 'diligenceReport',
            },
            {
                name: 'phieuDiemDanhThang',
            },
            {
                name: 'bcDanhSachHS',
            },
            {
                name: 'baoCaoDiemDanhVang',
            },
            {
                name: 'soDiemDanh',
            },
            {
                name: 'diemDanhSo',
            },
            {
                name: 'tongHopDonXinNghi',
            },
            {
                name: 'donXinNghiHS',
            },
            {
                name: 'bcDuyetNghi',
            },
            {
                name: 'student-attendance-book-report'
            },
            {
                name: 'bcTuongTacNhanXet',
            }
        ]
    },
    {
        name: "company_menu",
        children: [
            {
                name: 'companies',
            },
            {
                name: 'departments',
            },
            {
                name: 'groups',
            },
            {
                name: 'employeeEnrollments',
            },
            {
                name: 'dayOffs',
            },
            {
                name: 'dsMaQrNv',
            },
            {
                name: 'certificateBatchTemplate',
            },
            {
                name: 'customerCertificateBatches',
            },
            {
                name: 'employee-off-request-report'
            },
            {
                name: 'employee-off-request-detail-report'
            },
            {
                name: 'customer-internal-codes'
            }
        ]
    },
    {
        name: "resident_menu",
        children: [
            {
                name: 'companies',
            },
            {
                name: 'blocks',
            },
            {
                name: 'floors',
            },
            {
                name: 'apartments',
            },
            {
                name: 'residentEnrollments',
            },
            {
                name: 'dsMaQrCD',
            }
        ]
    },
    {
        name: "discipline_menu",
        children: [
            {
                name: 'disciplineCategories'
            },
            {
                name: 'disciplinePlans'
            },
            {
                name: 'customer-discipline'
            },
            {
                name: 'unit-discipline'
            },
            {
                name: 'discipline-report'
            },
            {
                name: 'bcNeNepTheoLop'
            },
            {
                name: 'bcXhThiDua'
            },
        ]
    },
    {
        name: "access_control_menu",
        children: [
            {
                name: 'accessDevices',
            },
            {
                name: 'events',
            },
            {
                name: 'eventGroups',
            },
            {
                name: 'eventBatches',
            },
            {
                name: 'eventLocations',
            },
            {
                name: 'calendars',
            },
            {
                name: 'calendarBatches',
            },
            {
                name: 'attendances',
            },
            {
                name: 'create-attendance',
            },
            {
                name: 'attendanceResultSummaries',
            },
            {
                name: 'guests',
            },
            {
                name: 'guestOuts',
            },
            {
                name: 'attendanceSimu',
            },
            {
                name: 'authorizeAttendances',
            },
            {
                name: 'dayOffs',
            },
            {
                name: 'authorizedAttendanceHistory'
            },
            {
                name: 'attendanceDetailsChangedHistories'
            },
            {
                name: 'finalizeAttendanceData'
            },
            {
                name: 'manualAccessControl'
            },
            {
                name: 'manualAttendance'
            },
            {
                name: 'introductionFiles'
            }
        ]
    },
    {
        name: "access_control_reports_menu",
        children: [
            {
                name: 'inReport',
            },
            {
                name: 'attendanceInOutReport',
            },
            {
                name: 'timeKeepingReport',
            },
            {
                name: 'bcChamCong',
            },
            {
                name: 'absentReport',
            },
            {
                name: 'guestReport',
            },
            {
                name: 'baoCaoDiemDanhGiaoVien',
            },
            {
                name: 'thongKeLuotRaVao',
            },
            {
                name: 'thongKeSoLieuDoNhietDo',
            },
            {
                name: 'chiTietNhietDoCao',
            },
            {
                name: 'chiTietNhietDoNv',
            },
            {
                name: 'bcChamThe',
            },
            {
                name: 'bangGioDiemDanh',
            },
            {
                name: 'tongHopDuyetDiemDanh',
            },
            {
                name: 'thongKeLuotChamThe',
            }
        ]
    },
    {
        name: "face",
        children: [
            {
                name: "face"
            },
            {
                name: "face/create"
            },
            {
                name: 'face-remove'
            },
            {
                name: "history-face-checkin"
            },
        ]
    },
    {
        name: "bao_cao_hoc_phan_menu",
        children: [
            {
                name: 'diemDanhLopHocPhan',
            },
            {
                name: 'tongHopDiemDanhHocPhan',
            }
        ]
    },
    {
        name: "bill_management_menu",
        children: [
            {
                name: 'billProducts',
            },
            {
                name: 'customerProducts',
            },
            {
                name: 'unitProducts',
            },
            {
                name: 'eventProducts',
            },
            {
                name: 'exemptionPolicies',
            },
            {
                name: 'exemptionPolicyDetails',
            },
            {
                name: 'exemptions',
            },
            {
                name: 'billBatchTemplate',
            },
            {
                name: 'billBatches',
            },
            {
                name: 'thongBaoHocPhi',
            },
            {
                name: 'tbHocPhiTheoKeHoach',
            },
            {
                name: 'billBatchItemNotify',
            },
            {
                name: 'billBatchItemNotifyV2',
            },
            {
                name: 'bill-batch-printing-a6'
            },
            {
                name: 'billItemNotifyByBatch',
            },
            {
                name: 'in-tb-hp-gop-ke-hoach',
            },
            {
                name: 'unpaidBillNotify',
            },
            {
                name: 'bankStatements',
            },
            {
                name: 'billViewEdits',
            },
            {
                name: 'billViewEditByMonth',
            },
            {
                name: 'contributionBills',
            },
            {
                name: 'tao-nhanh-thanh-toan',
            },
            {
                name: 'billViews',
            },
            {
                name: 'paymentViews',
            },
            {
                name: 'bankStatementTemplates',
            },
            {
                name: 'inNhieuHoaDon',
            },
            {
                name: 'paymentGatewayConfigurations',
            },
            {
                name: 'payment-gateway-configuration-history',
            },
            {
                name: 'tbNhacNo',
            },
            {
                name: 'debtReminderBatches',
            },
            {
                name: 'bankTransferConsolidates',
            }
        ]
    },
    {
        name: "bill_management_menu",
        children: [
            {
                name: 'partnerBillViews',
            },
            {
                name: 'partnerPaymentViews',
            }
        ]
    },
    {
        name: "bill_management_report",
        children: [
            {
                name: 'paymentPaidByProduct',
            },
            {
                name: 'bangKeTungDanhMuc',
            },
            {
                name: 'listPaidByProducts',
            },
            {
                name: 'paymentByDaysReport',
            },
            {
                name: 'paymentSchoolReport',
            },
            {
                name: 'deptStudentsReport',
            },
            {
                name: 'studentsHaveRemain',
            },
            {
                name: 'receiptUsage',
            },
            {
                name: 'paidFullDayStudentsReport',
            },
            {
                name: 'paidByMonthsReport',
            },
            {
                name: 'hsDaDongTienTheoDanhMuc',
            },
            {
                name: 'baoCaoSuDungBienLai',
            },
            {
                name: 'baoCaoHoaDonBiHuy',
            },
            {
                name: 'baoCaoBienLaiBiHuy',
            },
            {
                name: 'tongHopKhoanThuReport',
            },
            {
                name: 'chiTietHoaDonReport',
            },
            {
                name: 'baoCaoChiTietBienLai',
            },
            {
                name: 'baoCaoThuHocPhiDoiTacOnline',
            },
            {
                name: 'dsTkDinhDanh',
            },
            {
                name: 'tongHopBienLaiVaHoaDon',
            },
            {
                name: 'chiTietThuPhiTheoNoiDung',
            },
            {
                name: 'bcChiTietThu',
            },
            {
                name: 'bcChiTietPhiVaNguoiThu',
            },
            {
                name: 'exemption-policy-report'
            }
        ]
    },
    {
        name: "help_desk_bill_management_report",
        children: [
            {
                name: 'help-desk/bcChiTietPhiVaNguoiThu',
            }
        ]
    },
    {
        name: "contribution_bill_management_menu",
        children: [
            {
                name: 'contributionProducts',
            },
            {
                name: 'contributionBillBatchTemplate',
            },
            {
                name: 'contributionBillBatches',
            },
            {
                name: 'contributionBills',
            },
            {
                name: 'contributionBillViews',
            },
            {
                name: 'contributionPaymentViews',
            },
        ]
    },
    {
        name: "bill_account_management_menu",
        children: [
            {
                name: 'billAccountBatches',
            },
            {
                name: 'billAccounts',
            },
            {
                name: 'billAccountTransactions',
            },
            {
                name: 'baoCaoGiaoDichTaiKhoan',
            }
        ]
    },
    {
        name: "hospital_report",
        children: [
            {
                name: 'baoCaoTamUngNapThe',
            },
            {
                name: 'baoCaoHoanTamUngNapThe',
            },
            {
                name: 'baoCaoSoDuTaiKhoan',
            },
            {
                name: 'baoCaoThanhToanVienPhi',
            },
            {
                name: 'tongHopDungThe',
            },
            {
                name: 'luotKhamDungThe',
            }
        ]
    },
    {
        name: "meeting_menu",
        children: [
            {
                name: 'polls',
            },
            {
                name: 'votingReport',
            },
            {
                name: 'missingVotingReport',
            },
            {
                name: 'presentDeputyReport',
            },
        ],
    },
    {
        name: "semi_boarding_management",
        children: [
            {
                name: 'foods',
            },
            {
                name: 'foodMenus',
            },
            {
                name: 'foodMenuBatches',
            },
            {
                name: 'chiTraTienReport'
            },
            {
                name: 'suatAnBanTru'
            },
            {
                name: 'mealRegistration1'
            },
            {
                name: 'mealRegistration2'
            },
            {
                name: 'mealRegistration3'
            },
            {
                name: 'soLuongAnBanTru'
            },
            {
                name: 'soLuongAnBanTruKeToan'
            },
            {
                name: 'mealRegistrations'
            },
            {
                name: 'mealRegistrationChangedHistories'
            },
            {
                name: 'tkbBanTru'
            },
            {
                name: 'fullDayCalendarBatches'
            },
            {
                name: 'finalizeMealRegistration'
            },
            {
                name: 'full-day-calendar-batch'
            },
            {
                name: 'full-day-calendar-off-template'
            },
            {
                name: 'full-day-calendar-off-batches'
            },
            {
                name: 'semiBoardingAbsentReport',
            },
            {
                name: 'semiBoardingMissingCardReport',
            },
            {
                name: 'taoDangKySuatAn',
            },
            {
                name: 'bcNguTruaBanTru',
            },
            {
                name: 'bcDiemDanhBanTru',
            }
        ]
    },
    {
        name: "subject_result_management",
        children: [
            {
                name: 'customerSubjectResultBatches',
            },
            {
                name: 'customerSubjectResultBatchV2s',
            },
            {
                name: 'cauHinhCongBoDiem'
            },
            {
                name: 'congBoDiems'
            }
        ],
    },
    {
        name: "messaging_menu",
        children: [
            {
                name: 'messages',
            },
            {
                name: 'archiveMessageLogs',
            },
            {
                name: 'vinaIDMessages',
            }
        ],
    },
    {
        name: "sms_messaging_menu",
        children: [
            {
                name: 'smsDashboard',
            },
            {
                name: 'smsMessages',
            },
            {
                name: 'smsMessageLogs',
            },
            {
                name: 'thongKeSmsTheoNguoi',
            }
        ],
    },
    {
        name: "chat_menu",
        children: [
            {
                name: 'userChannels',
            }
        ],
    },
    {
        name: "health_menu",
        children: [
            {
                name: 'customerHealthBatchTemplate'
            },
            {
                name: 'customerHealthBatches',
            },
            {
                name: 'customerHealths',
            }
        ],
    },
    {
        name: "e_card_menu",
        children: [
            {
                name: 'eCardTemplates',
            },
            {
                name: 'DSTheDienTu',
            },
            {
                name: 'uploadTheDienTuSign',
            },
            {
                name: 'bcTheDienTu',
            }
        ],
    },
    {
        name: "user_menu",
        children: [
            {
                name: 'roles',
            },
            {
                name: 'users',
            },
            {
                name: 'customerImageBatch',
            },
            {
                name: 'PHKichHoatApp',
            },
            {
                name: 'bankTransferTopupConsolidates',
            }
        ]
    },
    {
        name: "config_menu",
        children: [
            {
                name: 'test',
            }
        ]
    },
    {
        name: "super_user_menu",
        children: [
            {
                name: 'tenants',
            },
            {
                name: 'superAdminUsers',
            },
            {
                name: 'settings',
            },
            {
                name: 'accessTerminals',
            },
            {
                name: 'paymentGatewayFees',
            },
            {
                name: 'vnEduStudentsReport',
            },
            {
                name: 'vduGatewayConfigurations',
            },
        ]
    },
    {
        name: "insurance_menu",
        children: [
            {
                name: 'insuranceBatches',
            }
        ]
    },
    {
        name: "others_menu",
        hidden: true,
    },
    {
        name: "category_menu",
        children: [
            {
                name: 'internalTenantSettings',
            },
            {
                name: 'tenantSettings',
            },
            {
                name: 'diligenceRanges'
            },
            {
                name: 'tenantBankAccounts',
            },
        ]
    },
    {
        name: "relationship_menu",
        children: [
            {
                name: 'relationshipMigrationBatchTemplate',
            },
            {
                name: 'relationshipMigrationBatches',
            }
        ]
    },
    {
        name: "card_application_menu",
        children: [
            {
                name: 'admissions-card-application'
            },
            {
                name: 'cardApplicationBatches',
            },
            {
                name: 'cardApplicationPhotoBatches',
            },
            {
                name: 'cardApplications',
            },
            {
                name: 'profileChangeLogs',
            },
            {
                name: 'profileChangeLogReadys',
            },
            {
                name: 'profileChangeLogCart'
            },
            {
                name: 'profileChangeLogReady2s',
            },
            {
                name: 'profileChangeLogCartHistory'
            }
        ]
    },
    {
        name: "kiot_menu",
        children: [
            {
                name: 'kiotConfigs',
            },
            {
                name: 'kiotImportOrders',
            },
            {
                name: 'kiotOrders',
            },
            {
                name: 'kiotDeliveredOrders',
            },
            {
                name: 'kiotProducts',
            },
            {
                name: 'kiotProductMenus',
            },
            {
                name: 'kiotProductMenuBatches',
            },
            {
                name: 'tong-hop-dat-hang',
            },
            {
                name: 'chamTheKhongHopLe',
            },
            {
                name: 'tong-hop-nhan-suat-an',
            },
            {
                name: 'bcChamTheNhanSuatAn',
            }
        ]
    },
    {
        name: "card_menu",
        children: [
            {
                name: 'update-card-template'
            },
            {
                name: 'cards'
            },
            {
                name: 'update-card-history'
            }
        ]
    },
    {
        name: "tuyen_sinh_menu",
        children: [
            {
                name: 'admissionCategories'
            },
            {
                name: 'admissionSubjects'
            },
            {
                name: 'admissionAdditionalServices'
            },
            {
                name: 'admissions'
            },
            {
                name: 'admissionChuyens'
            },
            {
                name: 'admission-dashboard'
            },
            {
                name: 'admissionChangeLogs'
            },
            {
                name: 'admissionReportTemplates'
            },
            {
                name: 'donTuyenSinh'
            },
            {
                name: 'bcTuyenSinh'
            },
            {
                name: 'bcTrangThaiTuyenSinh'
            },
            {
                name: 'admissionResultTemplates'
            }
        ]
    }
]
