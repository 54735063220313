import React from 'react';
import {Admin, Resource} from 'react-admin';

import env from './env';
import authProvider from './api/auth-provider';
import springRestProvider from './api/spring-data-rest';
import theme from './Theme';

import vietnameseMessages from "./i18n/vi";
import englishMessages from "./i18n/en";

import customRoutes from './customRoutes';

import Dashboard from './pages/Dashboard/Dashboard';
import customers from './pages/Customer';
import tenants from './pages/Tenant';
import superAdminUsers from './pages/SuperAdminUser';
import users from './pages/User';
import helpDeskUsers from './pages/User';
import roles from './pages/Role';
import settings from './pages/Setting';

import schools from './pages/School';
import grades from './pages/Grade';
import clazzes from './pages/Clazz';
import studentEnrollments from './pages/StudentEnrollment';

import companies from './pages/Company';
import departments from './pages/Department';
import employeeEnrollments from './pages/EmployeeEnrollment';
import guests from './pages/Guest';
import guestOuts from './pages/GuestOut';

import accessDevices from './pages/AccessDevice';
import eventGroups from './pages/EventGroup';
import eventLocations from './pages/EventLocation';
import calendars from './pages/Calendar';
import googleCalendars from './pages/GoogleCalendar';
import events from './pages/Event';
import eventDevices from './pages/EventDevice';
import eventUnits from './pages/EventUnit';
import units from './pages/Unit';
import baseUnits from './pages/Unit';
import attendances from './pages/Attendance';
import attendanceResultSummaries from './pages/AttendanceResultSummary';
import attendanceResultDetailses from './pages/AttendanceResultDetails';
import polls from './pages/Poll';
import messages from './pages/Message';
import archiveMessageLogs from './pages/ArchiveMessageLog';
import subMenus from './pages/SubMenu';
import roleSubMenus from './pages/RoleSubMenu';
import studentsByUnits from './pages/StudentsByUnits';
import authorizeAttendances from './pages/AttendanceResultAuthorization';
import billBatches from './pages/BillBatch';
import fullDayCalendarBatch from './pages/FullDayCalendarBatch';
import billProducts from './pages/BillProduct';
import contributionProducts from './pages/ContributionProduct';
import billViews from './pages/BillView';
import paymentViews from './pages/PaymentView';
import groups from './pages/Group';
import schoolYears from './pages/SchoolYear';
import exemptionPolicies from './pages/ExemptionPolicy';
import exemptionPolicyDetails from './pages/ExemptionPolicyDetail';
import rolePrivileges from './pages/RolePrivileges';
import exemptions from './pages/Exemption';
import bankStatements from './pages/BankStatement';
import unitProducts from './pages/UnitProduct';
import customerProducts from './pages/CustomertProduct';
import eventProducts from './pages/EventProduct';
import dayOffs from './pages/DayOff';
import authorizedAttendanceHistory from './pages/AuthorizedAttendanceHistory';
import cardApplications from './pages/CardApplication';
import cardApplicationBatches from './pages/CardApplicationBatch';
import diligenceRanges from './pages/DiligenceRange';
import attendanceDetailsChangedHistories from './pages/AttendanceDetailsChangedHistory';
import paymentGatewayFees from './pages/PaymentGatewayFees';
import paymentGatewayConfigurations from './pages/PaymentGatewayConfigurations';
import tenantBankAccounts from './pages/TenantBankAccount';
import finalizeAttendanceData from './pages/FinalizeClassAttendanceData';
import finalizeMealRegistration from './pages/FinalizeMealRegistration';
import manualAttendance from './pages/ManualAttendance';
import bankStatementTemplates from './pages/BankStatementTemplate';
import partnerBillViews from './pages/PartnerBillView';
import billViewEdits from './pages/BillViewEdit';
import partnerPaymentViews from './pages/PartnerPaymentView';
import vduGatewayConfigurations from './pages/VduGatewayConfiguration';
import partnerSchools from './pages/PartnerSchool';
import LoginPage from './LoginPage';
import foods from './pages/Food';
import foodMenus from './pages/FoodMenu';
import billAccountBatches from './pages/BillAccountBatch';
import billAccounts from './pages/BillAccount';
import billAccountTransactions from './pages/BillAccountTransaction';
import shiftDailies from './pages/ShiftDaliy';
import externalClientCounters from './pages/ExternalClientCounter';
import apartments from './pages/Apartment';
import blocks from './pages/Block';
import floors from './pages/Floor';
import residentEnrollments from './pages/ResidentEnrollment';
import apartmentViews from './pages/ApartmentView';
import manualAccessControl from './pages/ManualAccessControl';
import billItems from './pages/BillItem';
import clazzSubjects from './pages/ClazzSubject';
import subjects from './pages/Subject';
import customerSubjectResultBatches from './pages/CustomerSubjectResultBatch';
import insuranceBatches from './pages/InsuranceBatch';
import userChannels from './pages/UserChannel';
import eCardTemplates from './pages/ECardTemplate';
import profileChangeLogs from './pages/ProfileChangeLog';
import teacherClazzes from './pages/TeacherClazz';
import profileChangeLogReadys from './pages/ProfileChangeLogReady';
import cardApplicationNeedConfirms from './pages/CardApplicationNeedConfirm';
import eventBatches from './pages/EventBatch';
import studentEnrollmentBatches from './pages/StudentEnrollmentBatch';
import introductionFiles from './pages/IntroductionFile';
import foodMenuBatches from './pages/FoodMenuBatch';
import pupilEnrollmentBatches from './pages/PupilEnrollmentBatch';
import pupilEnrollmentBatchV3s from './pages/PupilEnrollmentV3Batch';
import contributionBills from './pages/ContributionBill';
import contributionBillBatches from './pages/ContributionBillBatch';
import accessTerminals from './pages/AccessTerminal';
import pupilEnrollmentBatchV2s from './pages/PupilEnrollmentV2Batch';
import mealRegistrations from './pages/MealRegistration';
import mealRegistrationChangedHistories from './pages/MealRegistrationChangeHistory';
import internalTenantSettings from './pages/InternalTenantSetting';
import face from './pages/Face';
import HistoryCheckinFaceID from './pages/HistoryFaceCheckin';
import profileChangeLogReady2s from './pages/ProfileChangeLogReady2';
import customerSubjectResultBatchV2s from './pages/CustomerSubjectResultBatchV2';
import smsMessages from './pages/SmsMessage';
import smsMessageLogs from './pages/SmsMessageLog';
import calendarBatches from './pages/CalendarBatch';
import congBoDiems from './pages/CongBoDiem';
import customerHealthBatches from './pages/CustomerHealthBatch';
import customerHealths from './pages/CustomerHealth';
import customerCertificateBatches from './pages/CustomerCertificateBatch';
import bankInfoes from './pages/BankInfo';
import debtReminderBatches from './pages/DebtReminderBatch';
import kiotConfigs from './pages/KiotConfig';
import fullDayCalendarOffBatches from './pages/FullDayCalendarOffBatch';
import relationshipMigrationBatches from './pages/RelationshipMigrationBatch';
import admissionSubjects from './pages/AdmissionSubject';
import admissions from './pages/Admission';
import admissionChangeLogs from './pages/AdmissionChangeLog';
import admissionAdditionalServices from './pages/AdmissionAdditionalService';
import admissionResultTemplates from './pages/AdmissionResultTemplate';
import admissionCategories from './pages/AdmissionCategory';
import admissionReportTemplates from './pages/AdmissionReportTemplate';
import admissionChuyens from './pages/AdmissionChuyen';
import disciplineCategories from './pages/DisciplineCategory';
import disciplinePlans from './pages/DisciplinePlan';
import admissionsCardApplication from './reports/AdmissionsCardApplication';
import teacherAssignmentBatches from './pages/TeacherAssignmentBatch';
import kiotProducts from './pages/KiotProduct';
import kiotProductMenus from './pages/KiotProductMenu';
import kiotProductMenuBatches from './pages/KiotProductMenuBatch';
import vinaIDMessages from './pages/VinaIDMessage';
import {Layout} from './layout';
import {schoolYearReducer} from './components';
import internalCode from "./pages/CustomerInternalCode";
import uploadPhotoPermission from "./pages/UploadPhotoPermission";
import customerDiscipline from "./pages/CustomerDiscipline";
import unitDiscipline from "./pages/UnitDiscipline";
import disciplineReport from "./pages/DisciplineReport";
import paymentGatewayConfigurationHistory from "./pages/PaymentGatewayConfigurationHistory";

import customerInfoBatches from "./pages/CustomerInfoBatch";

import bankTransferConsolidates from "./pages/BankTransferConsolidate";
import bankTransferTopupConsolidates from "./pages/BankTransferTopupConsolidate";
import clazzBatches from "./pages/ClazzBatch";

const dataProvider = springRestProvider(env.baseURL + '/api');

const i18messages = {
    en: englishMessages,
    vi: vietnameseMessages,
}
const i18nProvider = locale => i18messages[locale] || englishMessages;

const App = () => {
    //Reset localStorage every week
    React.useEffect(() => {
        let now = new Date().getTime();
        let setupTime = localStorage.getItem('setupTime');
        if (setupTime == null) {
            localStorage.setItem('setupTime', now.toString());
        } else {
            if (now - setupTime > 24 * 7 * 60 * 60 * 1000) {//1 week
                localStorage.clear();//reset items
                localStorage.setItem('setupTime', now.toString());
            }
        }
    }, []);

    return (
        <Admin locale="vi"
               loginPage={LoginPage}
               dashboard={Dashboard}
               appLayout={Layout}
               customReducers={{schoolYear: schoolYearReducer}}
               i18nProvider={i18nProvider}
               theme={theme}
               customRoutes={customRoutes}
               dataProvider={dataProvider}
               authProvider={authProvider(env.baseURL + '/login')}
        >
            <Resource name="schools"  {...schools} />
            <Resource name="grades"  {...grades} />
            <Resource name="clazzes"  {...clazzes} />
            <Resource name="studentEnrollments"  {...studentEnrollments} />
            <Resource name="companies"  {...companies} />
            <Resource name="departments"  {...departments} />
            <Resource name="employeeEnrollments"  {...employeeEnrollments} />
            <Resource name="guests"  {...guests} />
            <Resource name="guestOuts"  {...guestOuts} />

            <Resource name="accessDevices"  {...accessDevices} />
            <Resource name="eventGroups"  {...eventGroups} />
            <Resource name="events"  {...events} />
            <Resource name="calendars"  {...calendars} />
            <Resource name="googleCalendars"  {...googleCalendars} />
            <Resource name="eventDevices"  {...eventDevices} />
            <Resource name="eventUnits"  {...eventUnits} />
            <Resource name="units"  {...units} />
            <Resource name="attendances"  {...attendances} />
            <Resource
                name="attendanceResultSummaries"  {...attendanceResultSummaries} />
            <Resource
                name="attendanceResultDetailses"  {...attendanceResultDetailses} />
            <Resource name="messages"  {...messages} />
            <Resource name="archiveMessageLogs"  {...archiveMessageLogs} />
            <Resource name="roles"  {...roles} />
            <Resource name="users"  {...users} />
            <Resource name="subMenus"  {...subMenus} />
            <Resource name="roleSubMenus"  {...roleSubMenus} />
            <Resource name="settings"  {...settings} />
            <Resource name="tenants"  {...tenants} />
            <Resource name="superAdminUsers"  {...superAdminUsers} />
            <Resource name="customers"  {...customers} />
            <Resource name="polls"  {...polls} />
            <Resource name="studentsByUnits"  {...studentsByUnits} />
            <Resource name="authorizeAttendances"  {...authorizeAttendances} />
            <Resource name="billBatches"  {...billBatches} />
            <Resource name="billProducts"  {...billProducts} />
            <Resource name="billViews"  {...billViews} />
            <Resource name="billViewEdits"  {...billViewEdits} />
            <Resource name="paymentViews"  {...paymentViews} />
            <Resource name="groups"  {...groups} />
            <Resource name="tenantSettings"  {...settings} />
            <Resource name="schoolYears"  {...schoolYears} />
            <Resource name="bankStatementTemplates" {...bankStatementTemplates}  />
            <Resource name="exemptionPolicies"  {...exemptionPolicies} />
            <Resource name="exemptions"  {...exemptions} />
            <Resource name="exemptionPolicyDetails"  {...exemptionPolicyDetails} />
            <Resource name="rolePrivileges"  {...rolePrivileges} />
            <Resource name="bankStatements"  {...bankStatements} />
            <Resource name="unitProducts"  {...unitProducts} />
            <Resource name="eventProducts"  {...eventProducts} />
            <Resource name="customerProducts"  {...customerProducts} />
            <Resource name="dayOffs"  {...dayOffs} />
            <Resource name="authorizedAttendanceHistory"  {...authorizedAttendanceHistory} />
            <Resource name="cardApplications"  {...cardApplications} />
            <Resource name="cardApplicationBatches"  {...cardApplicationBatches} />
            <Resource name="diligenceRanges"  {...diligenceRanges} />
            <Resource name="eventLocations"  {...eventLocations} />
            <Resource name="attendanceDetailsChangedHistories"  {...attendanceDetailsChangedHistories} />
            <Resource name="paymentGatewayFees"  {...paymentGatewayFees} />
            <Resource name="paymentGatewayConfigurations"  {...paymentGatewayConfigurations} />
            <Resource name="tenantBankAccounts"  {...tenantBankAccounts} />
            <Resource name="finalizeAttendanceData"  {...finalizeAttendanceData} />
            <Resource name="manualAttendance"  {...manualAttendance} />
            <Resource name="vduGatewayConfigurations"  {...vduGatewayConfigurations} />
            <Resource name="foods"  {...foods} />
            <Resource name="foodMenus"  {...foodMenus} />
            <Resource name="partnerBillViews"  {...partnerBillViews} />
            <Resource name="partnerSchools"  {...partnerSchools} />
            <Resource name="partnerPaymentViews"  {...partnerPaymentViews} />
            <Resource name="billAccountBatches"  {...billAccountBatches} />
            <Resource name="billAccounts"  {...billAccounts} />
            <Resource name="billAccountTransactions"  {...billAccountTransactions} />
            <Resource name="shiftDailies"  {...shiftDailies} />
            <Resource name="externalClientCounters"  {...externalClientCounters} />
            <Resource name="apartments"  {...apartments} />
            <Resource name="blocks"  {...blocks} />
            <Resource name="floors"  {...floors} />
            <Resource name="residentEnrollments"  {...residentEnrollments} />
            <Resource name="apartmentViews"  {...apartmentViews} />
            <Resource name="manualAccessControl"  {...manualAccessControl} />
            <Resource name="billItems"  {...billItems} />
            <Resource name="clazzSubjects"  {...clazzSubjects} />
            <Resource name="subjects"  {...subjects} />
            <Resource name="customerSubjectResultBatches"  {...customerSubjectResultBatches} />
            <Resource name="vinaIDMessages"  {...vinaIDMessages} />
            <Resource name="baseUnits"  {...baseUnits} />
            <Resource name="insuranceBatches"  {...insuranceBatches} />
            <Resource name="userChannels"  {...userChannels} />
            <Resource name="eCardTemplates"  {...eCardTemplates} />
            <Resource name="profileChangeLogs"  {...profileChangeLogs} />
            <Resource name="teacherClazzes"  {...teacherClazzes} />
            <Resource name="profileChangeLogReadys"  {...profileChangeLogReadys} />
            <Resource name="cardApplicationNeedConfirms"  {...cardApplicationNeedConfirms} />
            <Resource name="eventBatches"  {...eventBatches} />
            <Resource name="studentEnrollmentBatches"  {...studentEnrollmentBatches} />
            <Resource name="introductionFiles"  {...introductionFiles} />
            <Resource name="foodMenuBatches"  {...foodMenuBatches} />
            <Resource name="pupilEnrollmentBatches"  {...pupilEnrollmentBatches} />
            <Resource name="contributionBills"  {...contributionBills} />
            <Resource name="contributionProducts"  {...contributionProducts} />
            <Resource name="contributionBillBatches"  {...contributionBillBatches} />
            <Resource name="accessTerminals"  {...accessTerminals} />
            <Resource name="pupilEnrollmentBatchV2s"  {...pupilEnrollmentBatchV2s} />
            <Resource name="pupilEnrollmentBatchV3s"  {...pupilEnrollmentBatchV3s} />
            <Resource name="mealRegistrations"  {...mealRegistrations} />
            <Resource name="mealRegistrationChangedHistories"  {...mealRegistrationChangedHistories} />
            <Resource name="internalTenantSettings"  {...internalTenantSettings} />
            <Resource name="full-day-calendar-batch"  {...fullDayCalendarBatch} />
            <Resource name="finalizeMealRegistration"  {...finalizeMealRegistration} />
            <Resource name="face" {...face} />
            <Resource name="history-face-checkin" {...HistoryCheckinFaceID} />
            <Resource name="profileChangeLogReady2s"  {...profileChangeLogReady2s} />
            <Resource name="customerSubjectResultBatchV2s"  {...customerSubjectResultBatchV2s} />
            <Resource name="smsMessages"  {...smsMessages} />
            <Resource name="smsMessageLogs"  {...smsMessageLogs} />
            <Resource name="calendarBatches"  {...calendarBatches} />
            <Resource name="congBoDiems"  {...congBoDiems} />
            <Resource name="customerHealthBatches"  {...customerHealthBatches} />
            <Resource name="customerHealths"  {...customerHealths} />
            <Resource name="customerCertificateBatches"  {...customerCertificateBatches} />
            <Resource name="bankInfoes"  {...bankInfoes} />
            <Resource name="debtReminderBatches"  {...debtReminderBatches} />
            <Resource name="relationshipMigrationBatches"  {...relationshipMigrationBatches} />
            <Resource name="kiotConfigs"  {...kiotConfigs} />
            <Resource name='customer-internal-codes' {...internalCode}/>
            <Resource name="full-day-calendar-off-batches"  {...fullDayCalendarOffBatches} />
            <Resource name="admissionSubjects"  {...admissionSubjects} />
            <Resource name="admissions"  {...admissions} />
            <Resource name="admissionChangeLogs"  {...admissionChangeLogs} />
            <Resource name="admissionAdditionalServices"  {...admissionAdditionalServices} />
            <Resource name="admissionResultTemplates"  {...admissionResultTemplates} />
            <Resource name="admissionCategories"  {...admissionCategories} />
            <Resource name="admissionReportTemplates"  {...admissionReportTemplates} />
            <Resource name="admissionChuyens"  {...admissionChuyens} />
            <Resource name="admissions-card-applications"  {...admissionsCardApplication} />
            <Resource name="upload-photo-permissions"  {...uploadPhotoPermission} />
            <Resource name="disciplineCategories"  {...disciplineCategories} />
            <Resource name="disciplinePlans"  {...disciplinePlans} />
            <Resource name="customer-discipline"  {...customerDiscipline} />
            <Resource name="unit-discipline"  {...unitDiscipline} />
            <Resource name="discipline-report"  {...disciplineReport} />
            <Resource name="teacherAssignmentBatches"  {...teacherAssignmentBatches} />
            <Resource name="helpDeskUsers"  {...helpDeskUsers} />
            <Resource name="payment-gateway-configuration-history"  {...paymentGatewayConfigurationHistory} />
            <Resource name="kiotProducts"  {...kiotProducts} />
            <Resource name="kiotProductMenus"  {...kiotProductMenus} />
            <Resource name="kiotProductMenuBatches"  {...kiotProductMenuBatches} />

            <Resource name="customerInfoBatches"  {...customerInfoBatches} />

            <Resource name="bankTransferConsolidates"  {...bankTransferConsolidates} />
            <Resource name="bankTransferTopupConsolidates"  {...bankTransferTopupConsolidates} />
            <Resource name="clazzBatches"  {...clazzBatches} />
        </Admin>
    )
}

export default App;
